import axios from 'axios'
// import ElementUI from 'element-ui';
// import { login } from '@/api/login.js'
import { Notification } from 'element-ui';
// import router from '@/router/index.js'
const baseURL = process.env.NODE_ENV === 'production' ? '/back' : '/apii'

const server = axios.create({
  baseURL: baseURL,
})
// 添加请求拦截器
server.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    // config['headers']['Content-Type'] = 'application/json'
    // if (localStorage.getItem('token')) {
    //   config['headers']['token'] = localStorage.getItem('token')
    // }
    return config
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
)

// 添加响应拦截器
server.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    console.log('')
    if (response.data.code != 0) {
      Notification.error({
        title: '温馨提示',
        message: response.data.msg 
      });
      // router.push({ path: '/login' })
    }
    // if (response.data.code == 11) {
    //   let userName = localStorage.getItem('userName')
    //   let password = localStorage.getItem('password')
    //   let pathName = localStorage.getItem('path')
    //   if (userName && password) {
    //     let obj = JSON.stringify({
    //       account : userName,
    //       pwd: password,
    //     })
    //     login(obj).then(res => {
    //       // let res = JSON.parse(xhr.responseText)
    //       console.log('res', res);
    //       if (res.code == 0 && res.data && res.data.token) {
    //         localStorage.setItem('token', res.data.token)
    //         Notification.success({
    //           title: '温馨提示',
    //           message: res.msg
    //         });
    //         if (pathName) {
    //           this.$router.push({ path: `/${pathName}` })
    //         } else {
    //           this.$router.push({ path: '/cycle'})
    //         }
    //       } else {
    //         router.push({ path: '/login' })
    //       }
    //     })
    //   } else {
    //     router.push({ path: '/login' })
    //   }
    // }
    // this.getToken()
    return response.data

  },
  error => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
)

export default server