<template>
  <div class="box">
    <div v-if="table.category == '食品'">
      <div class="top" :class="{ 'timeout': table.timeout }">
        <img src="@/assets/logo.jpg" alt="">
        <div>石家庄市食品从业人员健康检查</div>
      </div>
      <div v-if="table.timeout || table.status == 0"  class="center red">
        您的健康证已过期，请尽快重新办理
      </div>
      <div v-else class="center">
        您的健康证真实有效
      </div>
      <div class="bottom">
        <div>姓名: {{ (table.timeout || table.status == 0) ? '': table.name }}</div>
        <div>性别: {{ table.sex }}</div>
        <div>体检单位: {{ (table.timeout || table.status == 0) ? '--': table.unit }}</div>
        <div>体检日期: {{ (table.timeout || table.status == 0) ? '--': table.dataTime }}</div>
        <div>是否合格: {{ (table.timeout || table.status == 0) ? '--': table.timeout ? '不合格':'合格'}}</div>
      </div>
    </div>
    <div v-else>
      <div class="top2">
        公共卫生从业人员健康检查
      </div>
      <div  v-if="table.timeout || table.status == 0"  class="center red">
        您的健康证已过期，请尽快重新办理
      </div>
      <div v-else class="center">
        您的健康证真实有效
      </div>
      <div class="bottom">
        <div>姓名: {{ (table.timeout || table.status == 0) ? '': table.name }}</div>
        <div>性别: {{ table.sex }}</div>
        <div>体检单位: {{ (table.timeout || table.status == 0) ? '--': table.unit }}</div>
        <div>有效期至: {{ (table.timeout || table.status == 0) ? '--': table.timeoutDate }}</div>
        <div>是否合格: {{ (table.timeout || table.status == 0) ? '--': table.timeout ? '不合格':'合格'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from '@/api/index.js'
export default {
  components: {},
  props: {},
  data() {
    return {
      id: '',
      table: {
        category: '食品'
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.id = this.$route.query.id
    getData(this.$route.query.id).then(res => {
      this.table = res.data
      console.log('sub', res.data)
    })
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.box {
  min-height: 100vh;
  width: 100%;
}
.top {
  display: flex;
  justify-content: center;
  // justify-items: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 70px auto 40px;
  font-size: 18px;
  font-weight: 700;
  color: rgb(61, 61, 61);
  img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
}
.timeout {
  justify-content: center;
}
.top2 {
  width: fit-content;
  margin: 85px auto 40px;
  font-size: 18px;
  font-weight: 700;
  color: rgb(61, 61, 61);
}
.center {
  width: fit-content;
  color: rgb(0, 181, 120);
  height: 24px;
  font-size: 18px;
  font-weight: 700;
  margin: 0px auto 50px;
}
.bottom {
  padding: 20px;
  width: 85%;
  border-radius: 5px;
  background: #f5f6fa;
  margin: 0 auto;
  color: #3d3d3d;
  box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  outline: none;
  div {
    width: 100%;
    min-height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #3d3d3d;
    line-height: 30px;
  }
}
.red {
  color: red;
}
</style>


