import server from "@/axios/index.js";
// import qs from 'qs'

export function rental(data) {
  return server.request({
    method: "get",
    headers: {
      'Content-Type': 'application/json'
    },
    url: "/api/rental/get",
    params: data,
  });
}

export function submit(data) {
  return server.request({
    method: "post",
    headers: {
      'Content-Type': 'application/json'
    },
    url: "/api/rental/submit",
    data: data,
  });
}
export function deleteID(id) {
  return server.request({
    method: "delete",
    url: `/api/rental/delete/${id}`,
  });
}
export function getData(id) {
  return server.request({
    method: "get",
    url: `/api/rental/get/${id}`,
  });
}

